import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { useBusinesses, useRealmContext } from "@easybiz/context/src/RealmContext";
import {
  asyncPrepareTemplateData,
  formatTemplateRenderParams,
  formatTSPLCommands,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEMS,
  PLACEHOLDER_ORDER_PACKAGE_ID,
  PLACEHOLDER_ORDER_PACKAGES,
  TEMPLATE_CATEGORY_LABEL,
} from "@easybiz/utils";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

export default function useOrderLabelDisplay(firestore, order, printerSettings, configures) {
  const { isPackage, createImageData, stringCommand } = configures || {};
  const { onSnapshot, doc, getFirestore, getDoc, getDocs, query, where, limit, collection } = firestore;
  const realmId = useRealmId();
  const context = useRealmContext();
  const services = useBusinesses();
  const service = services?.find((service) => service.id === order?.get("service.id"));
  const agentId = order?.get("agent.id");
  const intl = useIntl();
  const [agentSetting, setAgentSetting] = useState();
  const [labelData, setLabelData] = useState(undefined);
  const settings = agentId ? agentSetting : service;
  const templateId = settings?.[isPackage ? "packageLabelId" : "itemLabelId"];

  // For B2B agent, load settings from agent document
  useEffect(() => {
    if (realmId && agentId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/factory_agents/${agentId}`), (snapshot) => {
        setAgentSetting(snapshot.data() || {});
      });
    }
  }, [realmId, agentId]);

  useEffect(() => {
    setLabelData(undefined);

    if (realmId && templateId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/templates/${TEMPLATE_CATEGORY_LABEL}/published/${templateId}`),
        (snapshot) => {
          const template = snapshot.get("data");

          if (template) {
            asyncPrepareTemplateData(
              template,
              {
                order: { ...order.data(), id: order.id },
              },
              context,
              ({ docPath, collectionPath, wheres, ...args }) => {
                if (docPath) {
                  return getDoc(doc(getFirestore(), docPath));
                } else if (collectionPath) {
                  return getDocs(
                    query(
                      collection(getFirestore(), collectionPath),
                      ...wheres.map(([field, operator, value]) => where(field, operator, value)),
                      limit(args.limit)
                    )
                  );
                }
              },
              [isPackage ? PLACEHOLDER_ORDER_PACKAGE_ID : PLACEHOLDER_ORDER_LINE_ITEM_ID]
            ).then((data) => setLabelData({ ...data, template }));
          }
        }
      );
    }
  }, [realmId, templateId, isPackage]);

  // null means template display not enabled
  return useMemo(() => {
    const renderParams =
      labelData &&
      formatTemplateRenderParams(
        order
          ? {
              ...labelData,
              dataSet: {
                ...labelData.dataSet,
                order: { ...order.data(), id: order.id },
              },
            }
          : labelData,
        { ...labelData.template.content, category: TEMPLATE_CATEGORY_LABEL },
        context
      );

    return settings && !templateId
      ? null
      : labelData && {
          template: labelData.template,
          renderParams,
          labels: (renderParams?.[isPackage ? PLACEHOLDER_ORDER_PACKAGES : PLACEHOLDER_ORDER_LINE_ITEMS] || [])
            .filter((item) => (isPackage ? true : item[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX]))
            .map((item) => {
              const [command, positions] = formatTSPLCommands(
                labelData.template,
                { ...renderParams, ...item },
                printerSettings,
                intl,
                createImageData,
                stringCommand
              );

              return {
                ...item,
                command,
                positions,
                key: isPackage
                  ? item[PLACEHOLDER_ORDER_PACKAGE_ID]
                  : `${item[PLACEHOLDER_ORDER_LINE_ITEM_ID] || ""}${
                      item[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX] || ""
                    }`,
              };
            }),
        };
  }, [labelData, order, settings, templateId]);
}
