"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (customerId) => {
  const realmId = useRealmId();
  const [membership, setMembership] = useState(null);

  useEffect(() => {
    setMembership(null);
    if (realmId && customerId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/memberships/${customerId}`), setMembership);
    }
  }, [realmId, customerId]);

  return membership;
};
