"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { collection, getDocs, getFirestore, limit, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (itemId) => {
  const realmId = useRealmId();
  const [histories, setHistories] = useState();

  useEffect(() => {
    setHistories(null);
    if (realmId && itemId) {
      getDocs(
        query(
          collection(getFirestore(), `realms/${realmId}/items/${itemId}/histories`),
          orderBy("createDate"),
          limit(300)
        )
      ).then((result) => setHistories(result.docs));
    }
  }, [realmId, itemId]);

  return histories;
};
