"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (emailId) => {
  const realmId = useRealmId();
  const [deviceDoc, setDeviceDoc] = useState();

  // Device doc
  useEffect(() => {
    if (emailId && realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/emails/${emailId}`), setDeviceDoc);
    }
  }, [emailId, realmId]);

  return deviceDoc;
};
