import { EditOutlined } from "@ant-design/icons";
import { useRegisteredDevice } from "@easybiz/context/src/DeviceRegistrationContext";
import { useOrderLabelDisplay } from "@easybiz/query";
import { PROCEDURE_PLANT_LABELING } from "@easybiz/utils";
import { textToImageData } from "@easybiz/web-component";
import { Button, Drawer, Empty, List } from "antd";
import * as firestore from "firebase/firestore";
import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import EditNoteDrawer from "../EditNoteDrawer";
import PhotoTakingPanel from "../PhotoTakingPanel";
import PrintLabelButton from "../PrintLabelButton";
import LabelListItemNew from "./LabelListItemNew";

export default function OrderLabelPanelNew({ placement, order, permissions }) {
  const [noteLabel, setNoteLabel] = useState();
  const [photoIndex, setPhotoIndex] = useState();
  const [noteAll, setNoteAll] = useState(false);
  const registerDevice = useRegisteredDevice();
  const labelPrinter = registerDevice?.get("labelPrinter");
  const labelDisplay = useOrderLabelDisplay(firestore, order, labelPrinter, {
    textToImageData,
  });

  const renderListItem = useCallback(
    (item) => {
      return (
        <LabelListItemNew
          template={labelDisplay?.template}
          renderParams={labelDisplay?.renderParams}
          order={order}
          item={item}
          onNote={permissions?.canNote ? setNoteLabel : null}
          onPhoto={permissions?.canNote ? setPhotoIndex : null}
        />
      );
    },
    [order, labelDisplay]
  );

  return (
    <React.Fragment>
      <div className="flex flex-row flex-space-between">
        <PrintLabelButton
          type="primary"
          printingParams={labelDisplay && { encodeLabels: (labelDisplay.labels || []).map(({ command }) => command) }}
        />
        <Button onClick={() => setNoteAll(true)} disabled={!permissions?.canNote} icon={<EditOutlined />}>
          <FormattedMessage tagName="span" defaultMessage="Label Note" />
        </Button>
      </div>
      <List
        loading={!labelDisplay}
        dataSource={labelDisplay?.labels || []}
        pagination={
          labelDisplay?.labels?.length > 25
            ? { position: "top", pageSize: 25, total: labelDisplay.labels.length }
            : false
        }
        renderItem={renderListItem}
        locale={{
          emptyText: <Empty description={<FormattedMessage defaultMessage={"No label generated yet"} />} />,
        }}
      />
      <br />
      <br />
      <br />
      <EditNoteDrawer
        open={Boolean(noteLabel) || noteAll}
        allItems={noteAll}
        noteLabel={noteLabel}
        order={order}
        onCancel={() => {
          setNoteLabel(null);
          setNoteAll(false);
        }}
        placement={placement}
      />
      <Drawer
        title={
          <span>
            <FormattedMessage tagName="span" defaultMessage="Taking photo" /> <b>#{photoIndex}</b>
          </span>
        }
        open={Boolean(photoIndex)}
        onClose={() => setPhotoIndex(null)}
        placement={placement}
        width={470}
      >
        {photoIndex && (
          <PhotoTakingPanel
            procedure={PROCEDURE_PLANT_LABELING}
            order={order}
            sortIndex={photoIndex}
            videoWidth={720}
            permissions={permissions}
          />
        )}
      </Drawer>
    </React.Fragment>
  );
}
