export default function formatCurrencySymbol(currencyCode) {
  return (0)
    .toLocaleString("en", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
}
