"use client";

import { createContext, useContext, useMemo } from "react";

const WorkflowUserContext = createContext();

export function WorkflowUserProvider({ client, user, avatarUrl, onSignOut, accountName, children }) {
  const context = useMemo(() => {
    return {
      account: user,
      uid: user?.uid,
      authTime: user?.authTime,
      token: user?.token,
      customerId: user?.customerId,
      customerScreen: user?.customerScreen,
      pendingSignIn: (user === false) || (user && !user?.uid),
      signInEmail: user?.email,
      emailVerified: user?.emailVerified,
      realmMaster: user?.realmMaster,
      platformMode: user?.platformMode,
      pendingSetup: user?.pendingSetup,
      registeredDeviceId: user?.installId,
      connectDeviceId: user?.connectDeviceId,
      checkInStaff: user?.checkInStaff,
      permissions: user?.permissions,
      businessCode: user?.businessCode,
      onSignOut,
      avatarUrl: avatarUrl || user?.avatarUrl,
      operator: user
        ? {
            id: user.customerId || user.uid,
            name: user.checkInStaff ? user.checkInStaff.name : accountName || user.name || "",
          }
        : null,
    };
  }, [client, user, avatarUrl, onSignOut, accountName]);

  return <WorkflowUserContext.Provider value={context}>{children}</WorkflowUserContext.Provider>;
}

export const useWorkflowUserId = () => useContext(WorkflowUserContext).uid;
export const useWorkflowUserAuthTime = () => useContext(WorkflowUserContext).authTime;
export const useWorkflowUserPendingSignIn = () => useContext(WorkflowUserContext).pendingSignIn;
export const useWorkflowUserPermissions = () => useContext(WorkflowUserContext).permissions;
export const useWorkflowUserBusinessCode = () => useContext(WorkflowUserContext).businessCode;
export const useWorkflowUserCustomerScreen = () => useContext(WorkflowUserContext).customerScreen;
export const useWorkflowUserPairedDeviceId = () => useContext(WorkflowUserContext).connectDeviceId;

export const useAuthUser = () => useContext(WorkflowUserContext).account;
export const useAuthUserToken = () => useContext(WorkflowUserContext).token;
export const useCustomerId = () => useContext(WorkflowUserContext).customerId;
export const useOperator = () => useContext(WorkflowUserContext).operator;
export const useSignInAvatarUrl = () => useContext(WorkflowUserContext).avatarUrl;
export const useSignInEmail = () => useContext(WorkflowUserContext).signInEmail;
export const useIsSignInEmailVerified = () => useContext(WorkflowUserContext).emailVerified;
export const useIsMasterAdmin = () => useContext(WorkflowUserContext).realmMaster;
export const useRegisteredDeviceId = () => useContext(WorkflowUserContext).registeredDeviceId;
export const useIsPlatformMode = () => useContext(WorkflowUserContext).platformMode;
export const useInPendingSetup = () => useContext(WorkflowUserContext).pendingSetup;
export const useCheckInStaff = () => useContext(WorkflowUserContext).checkInStaff;
export const useAuthSignOut = () => useContext(WorkflowUserContext).onSignOut;
