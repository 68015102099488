import { useBusinessCode } from "@easybiz/context/src/ConfigurationContext";
import { useBusinessDateCollectionDoc } from "@easybiz/web-firebase";
import { Progress, Spin, Tag } from "antd";
import React, { useMemo } from "react";

export default ({ date, readyForCollection }) => {
  const businessCode = useBusinessCode();
  const statusDoc = useBusinessDateCollectionDoc(businessCode, date);

  const result = useMemo(() => {
    if (!statusDoc) return null;

    const data = statusDoc.get("data");

    let orderTotal = 0,
      orderCollectionReady = 0,
      orderCompelte = 0,
      itemTotal = 0,
      itemComplete = 0;

    if (data) {
      for (const orderId in data) {
        const { c, r, w } = data[orderId];

        orderTotal++;

        if (w === 0) {
          orderCompelte++;
        }

        if (r || (c > 0 && w === 0)) {
          orderCollectionReady++;
        }

        if (c > 0) {
          itemComplete += c;
          itemTotal += c;
        }

        if (w > 0) {
          itemTotal += w;
        }
      }
    }

    if (orderTotal > 0) {
      return {
        orderCompelte,
        orderCollectionReady,
        orderTotal,
        itemComplete,
        itemTotal,
        progress: itemTotal > 0 ? parseInt((itemComplete / itemTotal) * 100) : 0,
      };
    } else {
      return false;
    }
  });

  if (result) {
    const { itemComplete, itemTotal, orderTotal, orderCollectionReady } = result;

    return readyForCollection ? (
      <Tag
        color={orderCollectionReady === orderTotal ? "success" : "processing"}
      >{`${orderCollectionReady}/${orderTotal}`}</Tag>
    ) : (
      <div>
        <Progress percent={result.progress} />
        <Tag color={itemComplete === itemTotal ? "success" : "processing"}>{`${itemComplete}/${itemTotal}`}</Tag>
      </div>
    );
  } else {
    return result === false ? null : <Spin />;
  }
};
