import { langZh as componentZh } from "@easybiz/component";
import { langZh as posZh } from "@easybiz/pos";
import { CLIENT_POS } from "@easybiz/utils";
import { AdminWebApplication, langZh as antdZh } from "@easybiz/web-admin";
import { ErrorBoundary, langZh as webComponentZh } from "@easybiz/web-component";
import { enableIndexedDb, langZh as firebaseZh } from "@easybiz/web-firebase";
import { initializeApp } from "firebase/app";
import React from "react";
import HelpCenterProvider from "./HelpCenterProvider";
import POSWebApplication from "./POSWebApplication";
import appZh from "./lang/zh.json";

// Must call before any firestore call
const offlineEnabled = window.localStorage ? !window.localStorage.getItem("disableoffline") : true;

const app = initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  siteKey: process.env.REACT_APP_siteKey,
});

if (offlineEnabled) {
  enableIndexedDb(app);
}

const CHINESE = Object.assign({}, appZh, posZh, componentZh, webComponentZh, firebaseZh, antdZh);

export default function () {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <AdminWebApplication offlineEnabled={offlineEnabled} client={CLIENT_POS} CHINESE={CHINESE}>
          <HelpCenterProvider>
            <POSWebApplication />
          </HelpCenterProvider>
        </AdminWebApplication>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
