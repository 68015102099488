"use client";

import { AccountAccessProvider } from "@easybiz/context/src/AccountAccessContext";
import { ConfigurationProvider } from "@easybiz/context/src/ConfigurationContext";
import { DeviceRegistrationProvider } from "@easybiz/context/src/DeviceRegistrationContext";
import { EmailStateProvider } from "@easybiz/context/src/EmailStateContext";
import { InstallationProvider } from "@easybiz/context/src/InstallationContext";
import { LiveStateProvider } from "@easybiz/context/src/LiveStateContext";
import { LocalSettingProvider } from "@easybiz/context/src/LocalSettingContext";
import { NamingProvider } from "@easybiz/context/src/NamingContext";
import { POSProvider } from "@easybiz/context/src/POSContext";
import { PhotoGalleryProvider } from "@easybiz/context/src/PhotoGalleryContext";
import { PrintingProvider } from "@easybiz/context/src/PrintingContext";
import { PushNotificationProvider } from "@easybiz/context/src/PushNotificationContext";
import { RealmProvider } from "@easybiz/context/src/RealmContext";
import { ServerAPIProvider } from "@easybiz/context/src/ServerAPIContext";
import { SubscriptionStateProvider } from "@easybiz/context/src/SubscriptionStateContext";
import { ThemingProvider } from "@easybiz/context/src/ThemingContext";
import { UploadProvider } from "@easybiz/context/src/UploadContext";
import { WorkflowUserProvider } from "@easybiz/context/src/WorkflowUserContext";
import {
  ACCOUNT_FIELD_EMAIL,
  ACCOUNT_FIELD_NAME,
  ACCOUNT_FIELD_PHONE_NUMBER,
  formatBusinessData,
} from "@easybiz/utils";
import { useMemo } from "react";
import useReactAppTheme from "../useReactAppTheme";

function AdminApplication({
  platform,
  appVersion,
  children,
  account,
  client,
  installationId,
  haveNetwork,
  onOTAUpdate,
  onOTACheck,
  otaNewVersion,
  realmDoc,
  businessDoc,
  deviceDoc,
  licenseDoc,
  customerScreenDoc,
  locale,
  onUpdateLocale,
  offlineEnabled,
  onSignOut,
  toast,
  speaker,
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  httpsFunction,
  storageUpload,
  avatarUrl,
  pushGranted,
  installed,
  installEvent,
  isMobile,
  LocalStorage,
}) {
  const theme = useReactAppTheme();
  const businessSetting = useMemo(() => businessDoc && formatBusinessData(businessDoc), [businessDoc]);
  const profileConfig = useMemo(() => {
    const customerFields = Array.isArray(realmDoc?.get("posSettings.customerFields"))
      ? realmDoc?.get("posSettings.customerFields")
      : [ACCOUNT_FIELD_NAME];
    return {
      askName: customerFields.indexOf(ACCOUNT_FIELD_NAME) >= 0,
      askEmail: customerFields.indexOf(ACCOUNT_FIELD_EMAIL) >= 0,
      askTel: customerFields.indexOf(ACCOUNT_FIELD_PHONE_NUMBER) >= 0,
    };
  }, [realmDoc]);

  return (
    <ConfigurationProvider
      realmId={realmDoc ? realmDoc.id : account?.realmId}
      businessCode={account?.businessCode}
      toast={toast}
      speaker={speaker}
      platform={platform}
      installationId={installationId}
      appVersion={appVersion}
      locale={locale}
      haveNetwork={haveNetwork}
      client={client}
      offlineEnabled={offlineEnabled}
      pushGranted={pushGranted}
      onUpdateLocale={onUpdateLocale}
      customerScreenDoc={customerScreenDoc}
      signupConfig={profileConfig}
      countryCode={realmDoc?.get("country.abbreviation")}
      dialCode={realmDoc?.get("telephone.dialCode")}
      city={realmDoc?.get("location.title")}
      currency={realmDoc?.get("currency")}
      geoCenter={realmDoc?.get("location")}
      services={realmDoc?.get("services")}
      stripeAccount={realmDoc?.get("stripeAccount")}
      isTestMode={realmDoc?.get("internalTest")}
    >
      <NamingProvider realmDoc={realmDoc}>
        <ThemingProvider theme={theme}>
          <InstallationProvider
            installed={installed}
            installEvent={installEvent}
            isMobile={isMobile}
            onOTAUpdate={onOTAUpdate}
            onOTACheck={onOTACheck}
            otaNewVersion={otaNewVersion}
          >
            <PrintingProvider
              ESCEncoder={ESCEncoder}
              usbIO={usbIO}
              bluetoothIO={bluetoothIO}
              connectPrinter={connectPrinter}
            >
              <WorkflowUserProvider
                user={account}
                onSignOut={onSignOut}
                avatarUrl={avatarUrl}
                accountName={account && realmDoc?.get(`accounts.${account.uid}`)}
              >
                <AccountAccessProvider account={account} realmDoc={realmDoc}>
                  <SubscriptionStateProvider licenseDoc={licenseDoc} realmDoc={realmDoc}>
                    <RealmProvider businessCode={account?.businessCode} realmDoc={realmDoc}>
                      <DeviceRegistrationProvider deviceDoc={deviceDoc}>
                        <LiveStateProvider>
                          <POSProvider businessSetting={businessSetting}>
                            <EmailStateProvider>
                              <UploadProvider storageUpload={storageUpload}>
                                <PhotoGalleryProvider>
                                  <ServerAPIProvider httpsFunction={httpsFunction}>
                                    <LocalSettingProvider LocalStorage={LocalStorage}>
                                      <PushNotificationProvider>{children}</PushNotificationProvider>
                                    </LocalSettingProvider>
                                  </ServerAPIProvider>
                                </PhotoGalleryProvider>
                              </UploadProvider>
                            </EmailStateProvider>
                          </POSProvider>
                        </LiveStateProvider>
                      </DeviceRegistrationProvider>
                    </RealmProvider>
                  </SubscriptionStateProvider>
                </AccountAccessProvider>
              </WorkflowUserProvider>
            </PrintingProvider>
          </InstallationProvider>
        </ThemingProvider>
      </NamingProvider>
    </ConfigurationProvider>
  );
}

export default AdminApplication;
