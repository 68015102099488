"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatTopUpOption } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import useTopUpOptionQuery from "./useTopUpOptionQuery";

export default (customerId) => {
  const realmId = useRealmId();
  const options = useTopUpOptionQuery();
  const [topUpRecord, setTopUpRecord] = useState(null);

  useEffect(() => {
    setTopUpRecord(null);
    if (realmId && customerId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/topup_records/${customerId}`), setTopUpRecord);
    }
  }, [realmId, customerId]);

  return useMemo(() => {
    if (Array.isArray(options)) {
      return options.map((option) => formatTopUpOption(option, topUpRecord));
    }
  }, [options, topUpRecord]);
};
