"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatNextOrderNumber } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

function useOrderRunningNumber(businessCode, formatNext, serviceId) {
  const realmId = useRealmId();
  const [orderRunningNo, setOrderRunningNo] = useState();

  useEffect(() => {
    setOrderRunningNo(null);
    if (realmId && businessCode && (!formatNext || serviceId)) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/businesses/${businessCode}/settings/order_no`),
        (counter) => setOrderRunningNo(formatNext ? formatNextOrderNumber(counter, serviceId) : counter)
      );
    }
  }, [realmId, businessCode, serviceId]);

  return orderRunningNo;
}

export default useOrderRunningNumber;
