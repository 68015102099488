import { useBusinessCode, useRealmId } from "@easybiz/context/src/ConfigurationContext";
import {
  useSetAutoCusReceipt,
  useSetAutoInternalReceipt,
  useSetAutoLabel,
  useSetSearchTabs,
  useSetShowPhoto,
} from "@easybiz/context/src/LocalSettingContext";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useEffect } from "react";

export default function () {
  const realmId = useRealmId();
  const businessCode = useBusinessCode();
  const setAutoCusReceipt = useSetAutoCusReceipt();
  const setAutoInternalReceipt = useSetAutoInternalReceipt();
  const setAutoLabel = useSetAutoLabel();
  const setSearchTabs = useSetSearchTabs();
  const setShowPhoto = useSetShowPhoto();

  useEffect(() => {
    if (realmId && businessCode) {
      getDoc(doc(getFirestore(), `realms/${realmId}/businesses/${businessCode}/settings/pos`)).then((setting) => {
        if (!setting.get("converted")) {
          setAutoCusReceipt(Number.isInteger(setting?.get("autoCusReceipt")) ? setting?.get("autoCusReceipt") : 1);
          setAutoInternalReceipt(
            Number.isInteger(setting?.get("autoInternalReceipt")) ? setting?.get("autoInternalReceipt") : 1
          );
          setAutoLabel(setting?.get("autoTagging"));
          setSearchTabs(setting?.get("searchtabs"));
          setShowPhoto(setting?.get("showPhoto") ?? true);

          setDoc(setting.ref, { converted: true }, { merge: true });
        }
      });
    }
  }, [realmId, businessCode]);
}
