"use client";

import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (realmId, deviceId) => {
  const [customerScreen, setCustomerScreen] = useState();

  useEffect(() => {
    if (realmId && deviceId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/customer_screens/${deviceId}`), setCustomerScreen);
    }
  }, [realmId, deviceId]);

  return customerScreen;
};
