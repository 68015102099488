import iconv from "iconv-lite";
import formatTSPLImageData from "./formatTSPLImageData.js";
import getLabelFontWeight from "./getLabelFontWeight.js";
import positionLabelBlocks from "./positionLabelBlocks.js";

function locateGlobalXY(block, blocks) {
  if (block.parentId) {
    const parentBlock = blocks.find((b) => b.id === block.parentId);

    if (parentBlock) {
      locateGlobalXY(parentBlock, blocks);

      block.x = parentBlock.x + block.left + (block.paddingLeft || 0);
      block.y = parentBlock.y + block.top + (block.paddingTop || 0);
    }
  } else {
    block.x = block.left;
    block.y = block.top;
  }
}

export default function formatTSPLCommands(
  template,
  renderParams,
  printerSettings,
  intl,
  createImageData,
  returnString
) {
  let command;
  let blocks = [];

  if (template && typeof template === "object") {
    let commands = [];
    const { mmGap, mmGapOffset, direction, speed, density, cutter, offsetVertical, language } = {
      ...template.attrs,
      ...template.content,
      ...printerSettings,
    };
    const mmWidth = Number.isInteger(template.attrs?.mmWidth) ? template.attrs.mmWidth : 110;
    const mmHeight = Number.isInteger(template.attrs?.mmHeight) ? template.attrs.mmHeight : 10;

    // Setup commands
    commands.push(
      ...[
        `SIZE ${mmWidth} mm,${mmHeight} mm`,
        `GAP ${mmGap || 0} mm,${mmGapOffset || 0} mm`,
        `DIRECTION ${direction || 0}`,
        `SHIFT ${Number.isInteger(offsetVertical) ? offsetVertical : 0}`,
        ...(Number.isInteger(speed) ? [`SPEED ${speed}`] : []),
        ...(language ? [`CODEPAGE ${language.codepage}`] : []),
        `DENSITY ${density || 8}`,
        `SET CUTTER ${cutter || 1}`,
        `CLS`,
      ]
    );

    blocks = positionLabelBlocks(template, intl, renderParams);
    blocks.forEach((block) => locateGlobalXY(block, blocks));

    // Formatting commands
    blocks.forEach((block) => {
      if (block.text) {
        if (createImageData && block.text.textToImage && block.width && block.height) {
          const imageData = createImageData(
            block.text.lines,
            block.width,
            block.height,
            block.text.height,
            getLabelFontWeight(block.text.density || 8)?.fontWeight
          );
          commands.push([
            `BITMAP ${block.x},${block.y},${Math.ceil(block.width / 8)},${block.height},${0},`,
            formatTSPLImageData(imageData),
          ]);
        } else {
          block.text.lines.forEach((line, index) => {
            commands.push(
              `TEXT ${block.text.rotation ? block.x + block.height : block.x},${block.y + index * block.text.height},"${
                block.text.name
              }",${block.text.rotation || 0},${block.text.scaleX},${block.text.scaleY},"${line}"`
            );
          });
        }
      } else if (block.qrCode) {
        commands.push(
          `QRCODE ${block.x},${block.y},${block.qrCode.eccLevel},${block.qrCode.cellWidth},A,0,"${block.qrCode.content}"`
        );
      }

      if (block.reverse) {
        commands.push(`REVERSE ${block.x},${block.y},${block.width},${block.height}`);
      }

      if (block.box) {
        commands.push(
          `BOX ${block.x},${block.y},${block.x + block.width},${block.y + block.height},${block.box.width}`
        );
      }
    });

    commands.push(`PRINT 1,1`);

    if (returnString) {
      command = `${commands.join("\n")}\n`;
    } else {
      // Encode all commands to bytes
      // Initialize a TextEncoder to convert strings to bytes
      const chunks = [];
      const encoder = language ? iconv : new TextEncoder();

      // Process each command in the array
      commands.forEach((command) => {
        if (typeof command === "string") {
          // Encode the string command and append '\r\n'
          const commandBytes = encoder.encode(command + "\r\n", language?.encoding);
          chunks.push(commandBytes);
        } else if (Array.isArray(command)) {
          // Handle the bitmap command
          const commandString = command[0];
          const bitmapDataArray = command[1]; // Should be a Uint8Array

          // Encode the bitmap command string and append '\r\n'
          const commandBytes = encoder.encode(commandString, language?.encoding);
          chunks.push(commandBytes);

          // Append the bitmap data
          chunks.push(bitmapDataArray);

          // Append '\r\n' after the bitmap data
          chunks.push(encoder.encode("\r\n", language?.encoding));
        }
      });

      // Calculate the total length of all chunks
      let totalLength = chunks.reduce((sum, chunk) => sum + chunk.length, 0);

      // Create a single Uint8Array to hold all data
      command = new Uint8Array(totalLength);

      // Merge all chunks into the result array
      let offset = 0;

      chunks.forEach((chunk) => {
        command.set(chunk, offset);
        offset += chunk.length;
      });
    }
  }

  return [command, blocks];
}
