"use client";

import { useAccess } from "@easybiz/context/src/AccountAccessContext";
import { useOperator } from "@easybiz/context/src/WorkflowUserContext";
import { useBusinessCode, useClientType } from "@easybiz/context/src/ConfigurationContext";
import { usePOSSettings } from "@easybiz/context/src/RealmContext";
import {
  CLIENT_POS,
  PERMISSION_DATA_CANCEL_ORDER,
  PERMISSION_DATA_REFUND,
  PERMISSION_WORKFLOW_CONFIRM_ORDER,
  PERMISSION_WORKFLOW_JOB_SCHEDULE,
  PERMISSION_WORKFLOW_PAYMENT,
  isDayLimited,
} from "@easybiz/utils";
import { useMemo } from "react";

function useOrderPermissions(order, isDayClosed) {
  const operator = useOperator();
  const client = useClientType();
  const businessCode = useBusinessCode();
  const { disableSalesReturn, cancelLimitDays } = usePOSSettings();
  const canEdit = useAccess(PERMISSION_WORKFLOW_CONFIRM_ORDER);
  const canPayment = useAccess(PERMISSION_WORKFLOW_PAYMENT);
  const canRefund = useAccess(PERMISSION_DATA_REFUND);
  const canCancel = useAccess(PERMISSION_DATA_CANCEL_ORDER);
  const canJobSchedule = useAccess(PERMISSION_WORKFLOW_JOB_SCHEDULE);

  return useMemo(() => {
    if (!order || order.get("voided")) return {};

    const isPOS = client === CLIENT_POS;
    const ownOrder = businessCode === order.get("business.id") || businessCode === order.get("supplier.id");
    const collectionPlace = businessCode === order.get("collectionPlace");
    const supplier = (businessCode || operator?.id) === order.get("supplier.id");
    const isTBD = order.get("pricingTBD") || Boolean(order.get("pricingConfirmDate"));
    const completed = order.get("completed");

    return {
      canEdit: !completed && (isPOS ? isTBD : typeof order?.get("fixedQty") !== "number" && canEdit),
      canPayment: isPOS ? !isDayClosed && (ownOrder || collectionPlace) : canPayment,
      canCancelPayment: isPOS ? !isDayClosed && ownOrder : canRefund,
      canRefund: isPOS ? false : canRefund,
      canRefundOverpaid: isPOS ? !isDayClosed : canRefund,
      canDischarge: isPOS ? !isDayClosed && (ownOrder || collectionPlace) : true,
      canNote: isPOS ? ownOrder || collectionPlace || supplier : true,
      canSalesReturn: isPOS ? !isDayClosed && !disableSalesReturn && (ownOrder || supplier) : true,
      canCancel: isPOS ? !isDayClosed && ownOrder && !isDayLimited(cancelLimitDays, order.get("date")) : canCancel,
      canRedo: isPOS ? !isDayClosed && (ownOrder || supplier) : true,
      canJobSchedule: !isPOS,
    };
  }, [
    order,
    operator?.id,
    businessCode,
    client,
    canEdit,
    canPayment,
    canRefund,
    canCancel,
    disableSalesReturn,
    cancelLimitDays,
    canJobSchedule,
    isDayClosed,
  ]);
}

export default useOrderPermissions;
