"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export default (memberId, customerId, date) => {
  const realmId = useRealmId();
  const [focStatusDoc, setFocStatusDoc] = useState();

  // FOC promotion only can have one
  useEffect(() => {
    setFocStatusDoc(null);
    if (realmId && memberId && customerId && date) {
      return onSnapshot(
        doc(
          getFirestore(),
          `realms/${realmId}/monthly_focs/${memberId}/months/${dayjs(date).format("YYYY-MM")}/customers/${customerId}`
        ),
        setFocStatusDoc
      );
    }
  }, [realmId, memberId, date, customerId]);

  return focStatusDoc;
};
