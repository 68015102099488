"use client";

import {
  PERMISSION_DATA_CUSTOMERS,
  PERMISSION_DATA_ORDERS,
  PERMISSION_DATA_REPORT,
  PERMISSION_SETTING_BUSINESS,
  PERMISSION_SETTING_MARKETING,
  PERMISSION_WORKFLOW_CASE_REPORTS,
  PERMISSION_WORKFLOW_JOB_SCHEDULE,
  PERMISSION_WORKFLOW_NEW_ONLINE_ORDER,
  PERMISSION_WORKFLOW_PRODUCTION,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_PAST_DUE,
  SUBSCRIPTION_STATUS_TRIALING,
  checkPermission,
} from "@easybiz/utils";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

const AccountAccessContext = createContext();

export function AccountAccessProvider({ account, realmDoc, adminDoc, children }) {
  const [mockSignIn, setMockSignIn] = useState();
  const [previewAppId, setPreviewAppId] = useState();

  const context = useMemo(() => {
    const {
      realmMaster,
      permissions,
      businessCode,
      specialists,
      limitBusinesses,
      procedures,
      limitAgents,
      storePlants,
    } = mockSignIn
      ? {
          ...account,
          ...mockSignIn,
        }
      : account || {};

    const businessLimited = Array.isArray(limitBusinesses) && limitBusinesses.length > 0;
    const onlineLicences = realmDoc?.get("onlineLicences");
    const storeLicences = realmDoc?.get("storeLicences");
    const status = realmDoc?.get("subscription.status");

    return {
      internalTest: realmDoc?.get("internalTest"),
      subscriptionValid: [
        SUBSCRIPTION_STATUS_ACTIVE,
        SUBSCRIPTION_STATUS_TRIALING,
        SUBSCRIPTION_STATUS_PAST_DUE,
      ].includes(status),
      realmMaster,
      permissionReady: Array.isArray(permissions) || Boolean(businessCode),
      permissions,
      specialists,
      agentId: Array.isArray(specialists) ? account?.uid : null,
      procedures,
      limitAgents,
      mockSignIn,
      preferences: adminDoc ? adminDoc.get("preferences") || {} : null,
      previewAppId,
      setMockSignIn,
      setPreviewAppId,
      adminDoc,
      ...(Array.isArray(storePlants) && storePlants.length > 0 && { storePlants }),
      ...(businessLimited && { limitBusinesses }),
      ...(Array.isArray(onlineLicences) &&
        onlineLicences.length > 0 && {
          onlineLicences: businessLimited
            ? onlineLicences.filter((code) => limitBusinesses.indexOf(code) >= 0)
            : onlineLicences,
        }),
      ...(Array.isArray(storeLicences) &&
        storeLicences.length > 0 && {
          storeLicences: businessLimited
            ? storeLicences.filter((code) => limitBusinesses.indexOf(code) >= 0)
            : storeLicences,
        }),
      plantLicence: realmDoc?.get("plantLicence"),
    };
  }, [mockSignIn, account, realmDoc, adminDoc, previewAppId]);

  return <AccountAccessContext.Provider value={context}>{children}</AccountAccessContext.Provider>;
}

export const useIsInternalTest = () => useContext(AccountAccessContext).internalTest;
export const useIsSubscriptionValid = () => useContext(AccountAccessContext).subscriptionValid;
export const useAppLicenses = () => useContext(AccountAccessContext).onlineLicences;
export const useStoreLicenses = () => useContext(AccountAccessContext).storeLicences;
export const usePlantLicense = () => useContext(AccountAccessContext).plantLicence;
export const useCurrentAdminDoc = () => useContext(AccountAccessContext).adminDoc;

export const useAccess = (...keys) => {
  const context = useContext(AccountAccessContext);

  if (keys.length === 0) {
    return false;
  } else {
    return Boolean(keys.find((key) => checkPermission(key, context)));
  }
};

export const useIsPermissionReady = () => useContext(AccountAccessContext).permissionReady;
export const useLimitBusinsses = () => useContext(AccountAccessContext).limitBusinesses;
export const useLimitProcedures = () => useContext(AccountAccessContext).procedures;
export const useLimitAgents = () => useContext(AccountAccessContext).limitAgents;
export const useStorePlants = () => useContext(AccountAccessContext).storePlants;
export const useBusinessAgentId = () => useContext(AccountAccessContext).agentId;
export const useAccountPreferences = () => useContext(AccountAccessContext).preferences;
export const useCheckIsLimited = () => {
  const { realmMaster, limitBusinesses } = useContext(AccountAccessContext);

  return useCallback(
    (businessCode) => {
      if (realmMaster) {
        return false;
      } else if (!Array.isArray(limitBusinesses) || limitBusinesses.length === 0) {
        return false;
      } else {
        return limitBusinesses.indexOf(businessCode) < 0;
      }
    },
    [realmMaster, limitBusinesses]
  );
};

// Page access
export const useAccessOnline = () => {
  return useAccess(
    PERMISSION_DATA_REPORT,
    PERMISSION_WORKFLOW_NEW_ONLINE_ORDER,
    PERMISSION_DATA_ORDERS,
    PERMISSION_SETTING_BUSINESS
  );
};

export const useAccessStore = () => {
  return useAccess(PERMISSION_DATA_REPORT, PERMISSION_DATA_ORDERS, PERMISSION_SETTING_BUSINESS);
};

export const useAccessLogistic = () => {
  return useAccess(PERMISSION_WORKFLOW_JOB_SCHEDULE);
};

export const useAccessOperation = () => {
  return useAccess(PERMISSION_WORKFLOW_CASE_REPORTS, PERMISSION_WORKFLOW_PRODUCTION);
};

export const useAccessReports = () => {
  return useAccess(PERMISSION_DATA_REPORT);
};

export const useAccessCustomers = () => {
  return useAccess(PERMISSION_DATA_CUSTOMERS);
};

export const useAccessMarketing = () => {
  return useAccess(PERMISSION_SETTING_MARKETING);
};

export const useAccessPreviewAppId = () => useContext(AccountAccessContext).previewAppId;
export const useAccessSetPreviewAppId = () => useContext(AccountAccessContext).setPreviewAppId;

// Mock sign in
export const useIsMockSignIn = () => Boolean(useContext(AccountAccessContext).mockSignIn);
export const useMockSignInName = () => useContext(AccountAccessContext).mockSignIn?.displayName;
export const useSetMockSignIn = () => useContext(AccountAccessContext).setMockSignIn;
