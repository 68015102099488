"use client";

import { useAdminDeviceRegister } from "@easybiz/api-admin";
import { useClientType, useInstallationId } from "@easybiz/context/src/ConfigurationContext";
import { useDeviceLocation } from "@easybiz/context/src/DeviceRegistrationContext";
import { usePushToken } from "@easybiz/context/src/PushNotificationContext";
import { useWorkflowUserAuthTime, useWorkflowUserId } from "@easybiz/context/src/WorkflowUserContext";
import dayjs from "dayjs";
import { useEffect } from "react";

function DeviceRegister({ multiStation, accountDoc, getDeviceInfo, onRegistered, onSignOut, betaRelease }) {
  const uid = useWorkflowUserId();
  const authTime = useWorkflowUserAuthTime();
  const client = useClientType();
  const installId = useInstallationId();
  const pushToken = usePushToken();
  const location = useDeviceLocation();
  const { onSubmit, response, onClear } = useAdminDeviceRegister((succeed, response) => {
    if (succeed) {
      onRegistered(response);
    }
  });

  useEffect(() => {
    if (response?.errorCode) {
      let description, button;

      onSignOut("Login session expired", description, button);
      onClear();
    }
  }, [Boolean(response?.errorCode)]);

  useEffect(() => {
    if (installId && uid) {
      // Init permission register
      onSubmit({ ...getDeviceInfo(), installId, pushToken, ...(location && { location }), betaRelease });
    }
  }, [uid, installId, pushToken, multiStation && location?.failed ? location.timestamp : null, betaRelease]);

  useEffect(() => {
    if (
      !multiStation &&
      client &&
      installId &&
      authTime &&
      accountDoc?.get(client) &&
      accountDoc?.get(client).authTime > authTime
    ) {
      if (accountDoc?.get(client).id !== installId) {
        // Sign out
        onSignOut(
          "Login session expired",
          `Your account has been logged in another device ID:${accountDoc?.get(client).id} at ${dayjs
            .unix(accountDoc?.get(client).authTime)
            .format("HH:mm DD MMM YYYY")}, you can enable device whitelist to limit device access to your system.`
        );
      } else {
        onSubmit({ ...getDeviceInfo(), installId, pushToken });
      }
    }
  }, [
    Boolean(multiStation),
    client,
    installId,
    authTime,
    accountDoc?.get(client)?.authTime,
    accountDoc?.get(client)?.id,
  ]);

  return null;
}

export default DeviceRegister;
