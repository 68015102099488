"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { BUSINESS_SECTOR_LAUNDRY } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (service) => {
  const realmId = useRealmId();
  const [data, setData] = useState();

  useEffect(() => {
    setData(null);

    if (realmId && service) {
      let documentPath, version;

      switch (service?.sector) {
        case BUSINESS_SECTOR_LAUNDRY:
          // Have version control
          if (service.pricing) {
            documentPath = `realms/${realmId}/services/${service.id}/pricings/${service.pricing}`;
            version = service.pricing;
          } else {
            documentPath = `realms/${realmId}/previews/pricing-${service.id}`;
          }
          break;
        default:
          // No version control
          documentPath = `realms/${realmId}/services/${service.id}/pricings/internal`;
      }

      return onSnapshot(doc(getFirestore(), documentPath), (pricing) =>
        setData({ ...pricing.data(), ...(version && { version }) })
      );
    }
  }, [realmId, service?.id, service?.sector, service?.pricing]);

  return data;
};
