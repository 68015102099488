"use client";

import { formatCurrencySymbol } from "@easybiz/utils";
import { createContext, useContext, useMemo, useState } from "react";

const ConfigurationContext = createContext();

export function ConfigurationProvider({
  hostname,
  realmId,
  countryCode,
  dialCode,
  city,
  currency,
  geoCenter,
  services,
  stripeAccount,
  isTestMode,
  businessCode,
  appVersion,
  platform,
  debugMode,
  client,
  toast,
  speaker,
  customerScreenDoc,
  haveNetwork,
  offlineEnabled,
  pushGranted,
  locale,
  onUpdateLocale,
  draftMode,
  children,
  primaryColor,
  theme,
  signupConfig,
  installationId,
  isHeadless,
}) {
  const [sideWindow, setSideWindow] = useState();
  const installId = installationId;

  const context = useMemo(() => {
    return {
      hostname,
      realmId,
      businessCode,
      platform,
      haveNetwork,
      client,
      locale,
      onUpdateLocale,
      offlineEnabled,
      installId,
      toast,
      speaker,
      pushGranted,
      appVersion,
      customerScreenDoc,
      draftMode,
      primaryColor,
      theme,
      countryCode,
      dialCode,
      city,
      currency,
      currencySymbol: currency && formatCurrencySymbol(currency),
      geoCenter,
      services,
      stripeAccount,
      isTestMode,
      setSideWindow,
      signupConfig,
      isHeadless,
      sideWindow,
      debugMode
    };
  }, [
    hostname,
    realmId,
    businessCode,
    platform,
    toast,
    speaker,
    locale,
    onUpdateLocale,
    client,
    installId,
    offlineEnabled,
    haveNetwork,
    pushGranted,
    appVersion,
    customerScreenDoc,
    draftMode,
    primaryColor,
    theme,
    countryCode,
    dialCode,
    city,
    currency,
    geoCenter,
    services,
    stripeAccount,
    isTestMode,
    signupConfig,
    isHeadless,
    sideWindow,
    debugMode
  ]);
  return <ConfigurationContext.Provider value={context}>{children}</ConfigurationContext.Provider>;
}

// Realm
export const useHostname = () => useContext(ConfigurationContext).hostname;
export const useCountryCode = () => useContext(ConfigurationContext).countryCode;
export const useDefaultDialCode = () => useContext(ConfigurationContext).dialCode;
export const useCityName = () => useContext(ConfigurationContext).city;
export const useRealmCenter = () => useContext(ConfigurationContext).geoCenter;
export const useCurrency = () => useContext(ConfigurationContext).currency;
export const useCurrencySymbol = () => useContext(ConfigurationContext).currencySymbol;
export const useServices = () => useContext(ConfigurationContext).services;
export const useStripeAccount = () => useContext(ConfigurationContext).stripeAccount;
export const useIsTestMode = () => useContext(ConfigurationContext).isTestMode;

export const useSignupConfig = () => useContext(ConfigurationContext).signupConfig;
export const useTheme = () => useContext(ConfigurationContext).theme;
export const useRealmId = () => useContext(ConfigurationContext).realmId;
export const useIsTemplateRealm = () => useContext(ConfigurationContext).realmId === "KkDOots6r0eLGwLlzrqw";
export const useBusinessCode = () => useContext(ConfigurationContext).businessCode;
export const useDraftMode = () => useContext(ConfigurationContext).draftMode;
export const usePlatform = () => useContext(ConfigurationContext).platform;
export const useInstallationId = () => useContext(ConfigurationContext).installId;
export const useToast = () => useContext(ConfigurationContext).toast;
export const useSpeaker = () => useContext(ConfigurationContext).speaker;
export const useCustomerScreen = () => useContext(ConfigurationContext).customerScreenDoc;
export const useNotificationPermission = () => useContext(ConfigurationContext).pushGranted;
export const useLocale = () => useContext(ConfigurationContext).locale;
export const useSetLocale = () => useContext(ConfigurationContext).onUpdateLocale;
export const useClientType = () => useContext(ConfigurationContext).client;
export const useIsOfflineEnabled = () => useContext(ConfigurationContext).offlineEnabled;
export const useHaveNetwork = () => useContext(ConfigurationContext).haveNetwork;
export const useAppVersion = () => {
  return useContext(ConfigurationContext).appVersion || process.env.REACT_APP_VERSION;
};
export const useIsHeadlessMode = () => useContext(ConfigurationContext).isHeadless;
export const usePrimaryColor = () => useContext(ConfigurationContext).primaryColor;
export const useErrorColor = () => `#B3261E`;
export const useSideWindow = () => useContext(ConfigurationContext).sideWindow;
export const useSetSideWindow = () => useContext(ConfigurationContext).setSideWindow;
export const useIsDebugMode = () => useContext(ConfigurationContext).debugMode;

// Static configuration
export const useStripeKey = () => process.env.REACT_APP_stripe_pk || process.env.NEXT_PUBLIC_stripe_pk;
export const useStripeTestKey = () => "pk_test_BLYpxkqZV6sayItu1Vlcii5t"; // process.env.REACT_APP_stripe_pk_test || process.env.NEXT_PUBLIC_stripe_pk_test;
export const useStripeClientId = () => process.env.REACT_APP_tripe_client_id || process.env.NEXT_PUBLIC_tripe_client_id;
export const useStripeTestClientId = () =>
  process.env.REACT_APP_tripe_client_id || process.env.NEXT_PUBLIC_tripe_client_id_test;
export const useImageHost = () => process.env.REACT_APP_image_host || process.env.NEXT_PUBLIC_image_host;
export const useStorageHost = () => process.env.REACT_APP_storage_host || process.env.NEXT_PUBLIC_storage_host;
export const usePushNotificationVapidKey = () => process.env.REACT_APP_vapidKey || process.env.NEXT_PUBLIC_vapidKey;
export const useSiteKey = () => process.env.REACT_APP_siteKey || process.env.NEXT_PUBLIC_siteKey;
export const useGoogleMapApiKey = () => process.env.REACT_APP_google_map_pk || process.env.NEXT_PUBLIC_google_map_pk;
export const usePrinterServiceId = () =>
  process.env.REACT_APP_PRINTER_SERVICE_ID || process.env.NEXT_PUBLIC_PRINTER_SERVICE_ID;
export const usePortalHost = () =>
  `https://${process.env.REACT_APP_PLATFORM_HOST || process.env.NEXT_PUBLIC_PLATFORM_HOST}`;
export const useMobileDownloadLink = () => process.env.REACT_APP_MOBILE_LINK || process.env.NEXT_PUBLIC_MOBILE_LINK;
export const usePOSDownloadLink = () => process.env.REACT_APP_POS_LINK || process.env.NEXT_PUBLIC_POS_LINK;
export const useWebHostingDomain = () => process.env.REACT_APP_WEBDOMAIN || process.env.NEXT_PUBLIC_WEBDOMAIN;
export const usePOSUrl = () =>
  `https://pos.${process.env.REACT_APP_PLATFORM_HOST || process.env.NEXT_PUBLIC_PLATFORM_HOST}`;
export const useStorageBucket = () => `gs://easybiz-asean`;
