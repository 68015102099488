"use client";

import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { useCallback } from "react";

export default function useCallableFunction() {
  return useCallback((params) => {
    const functions = getFunctions();
    const isStaging = window?.location?.hostname?.indexOf("staging") >= 0;

    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    return httpsCallable(functions, isStaging ? "stagingOperationCreate" : "operationCreate")(params);
  }, []);
}
