"use client";

import { BUSINESS_ID_FACTORY, CLIENT_POS, getGSTRate, processOrderPricing } from "@easybiz/utils";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useClientType, useRealmId } from "./ConfigurationContext";
import { useBusinesses, useCompanies } from "./RealmContext";

const CheckoutContext = React.createContext();

export function CheckoutProvider({
  newOrderType,
  isReturnRedo,
  businessCode,
  service,
  customer,
  pricingData,
  bookingDoc,
  children,
  promotions,
  lineItemsSteps,
  onSwitchBusiness,
  onSwitchService,
  onSwitchCustomer,
  onDraft,
  flatMode,
  initParams,
  editOrder,
  setEditOrder,
}) {
  const realmId = useRealmId();
  const client = useClientType();
  const [editItem, setEditItem] = useState(null);
  const [editParams, setEditParams] = useState(initParams);
  const draftId = businessCode && service && customer && `${businessCode}-${service.id}-${customer.id}`;
  const companys = useCompanies();
  const businesses = useBusinesses();
  const gstRate = getGSTRate(businessCode, companys);

  useEffect(() => {
    setEditItem(null);
    setEditParams(initParams);
  }, [service?.id]);

  useEffect(() => {
    const found = service && Array.isArray(businesses) && businesses.find((item) => item.id === service.id);
    if (
      onSwitchService &&
      found &&
      (found.storeMin !== service.storeMin ||
        found.appMin !== service.appMin ||
        found.supplierId !== service.supplierId)
    ) {
      onSwitchService(found);
    }
  }, [businesses]);

  // Draft/Reset saving
  useEffect(() => {
    // Check for draft
    if (onDraft && draftId && !editOrder) {
      // Load
      onDraft(draftId, setEditParams);

      return () => {
        // Save
        onDraft(draftId, setEditParams, true);
      };
    } else {
      setEditParams(initParams);
    }
  }, [draftId, setEditParams, Boolean(editOrder)]);

  useEffect(() => {
    const { items } = editParams || {};

    if (Array.isArray(items) && editItem && Number.isInteger(editItem.itemIndex)) {
      if (
        items[editItem.itemIndex]?.productId === editItem.productId &&
        items[editItem.itemIndex]?.subTypeId === editItem.subTypeId
      ) {
        setEditItem({
          ...items[editItem.itemIndex],
          itemIndex: editItem.itemIndex,
        });
      } else {
        setEditItem({ productId: editItem.productId });
      }
    }
  }, [editParams]);

  const checkoutParams = useMemo(() => {
    const { promoDisableMap, promoCode, ...otherFields } = editParams || {};
    return {
      service,
      promotions: [
        ...(editOrder
          ? editOrder.get("promotions") || [
              // TODO: BACKWARD SUPPORT DELETE
              ...(editOrder.get("customerPromotions") || []).map((promotion) => ({
                ...promotion,
                member: true,
              })),
              ...(editOrder.get("campaignPromotions") || []),
              ...(editOrder.get("promoCodePromotion")
                ? [{ ...editOrder.get("promoCodePromotion"), promocode: true }]
                : []),
              ...(editOrder.get("voucherPromotion") ? [{ ...editOrder.get("voucherPromotion"), voucher: true }] : []),
            ]
          : promotions || []),
        ...(promoCode ? [promoCode] : []),
      ]
        .map((promotion) => {
          if (promoDisableMap && promoDisableMap[promotion.id]) {
            if (promotion.promocode) {
              return null;
            } else {
              return { ...promotion, disabled: true };
            }
          } else {
            return promotion;
          }
        })
        .filter((promotion) => promotion),
      minimumOrder:
        service?.id === BUSINESS_ID_FACTORY
          ? customer?.minimumOrder
          : client === CLIENT_POS
          ? service?.storeMin
          : service?.appMin,
      gstRate,
      ...otherFields,
    };
  }, [
    editOrder,
    editParams,
    promotions,
    gstRate,
    service?.id,
    service?.storeMin,
    service?.appMin,
    customer?.minimumOrder,
    client,
  ]);

  const context = useMemo(() => {
    return {
      pricing: processOrderPricing({
        type: editOrder ? editOrder.get("type") : newOrderType,
        ...(editOrder && {
          items: editOrder.get("items"),
          delivery: editOrder.get("delivery"),
          adjustments: editOrder.get("adjustments"),
        }),
        // Checkout params overwrite order data
        ...checkoutParams,
        ...(checkoutParams?.returnSchedule && {
          delivery: checkoutParams?.returnSchedule,
        }),
        // Fixed fields overwrite checkout params
        ...(editOrder && {
          minimumOrder: editOrder.get("minimumOrder"),
          gstRate: editOrder.get("gstRate"),
        }),
        ...(isReturnRedo && {
          creditNotes: null,
          payments: null,
          minimumOrder: null,
          adjustments: null,
        }),
      }),
      pricingData,
      editOrder,
      dirty: Boolean(editParams && editParams !== initParams),
      businessCode: editOrder ? editOrder.get("business.id") : businessCode,
      editItem,
      service: editOrder ? editOrder.get("service") : service,
      customer: editOrder ? editOrder.get("customer") : customer,
      supplierId: editOrder ? editOrder.get("supplier.id") : service?.supplierId,
      checkoutParams,
      lineItemsSteps,
      bookingDoc,
      setEditOrder,
      setEditParams,
      onSwitchBusiness,
      onSwitchService,
      onSwitchCustomer: (newCustomer) => {
        onSwitchCustomer(newCustomer);
        setEditItem(null);
      },
      setEditItem,
      onTogglePromotion: (promoId) => {
        if (promoId === editParams?.promoCode?.id) {
          setEditParams({ ...editParams, promoCode: null });
        } else {
          setEditParams({
            ...editParams,
            promoDisableMap: {
              ...editParams?.promoDisableMap,
              [promoId]: !(editParams?.promoDisableMap && editParams?.promoDisableMap[promoId]),
            },
          });
        }
      },
      onUpdateCheckoutParams: (updates) => {
        if (updates?.promoCode && editParams?.promoDisableMap && editParams.promoDisableMap[updates?.promoCode.id]) {
          // For same promo code applied after removal, just enable it back
          setEditParams({
            ...editParams,
            promoDisableMap: { ...editParams.promoDisableMap, [updates?.promoCode.id]: false },
          });
        } else {
          setEditParams({ ...editParams, ...updates });
        }
      },
      onClearCheckout: (clearCustomer) => {
        setEditParams(null);
        setEditItem(null);

        if (onDraft && draftId) {
          onDraft(draftId);
        }

        if (clearCustomer) {
          onSwitchCustomer(null);
        }
      },
    };
  }, [
    editOrder,
    businessCode,
    service,
    customer,
    editParams,
    checkoutParams,
    realmId,
    pricingData,
    lineItemsSteps,
    promotions,
    editItem,
    bookingDoc,
    onDraft,
    flatMode,
    draftId,
  ]);

  return <CheckoutContext.Provider value={context}>{children}</CheckoutContext.Provider>;
}

export const useCheckoutPricing = () => useContext(CheckoutContext).pricing;
export const useCheckoutTotal = () => useContext(CheckoutContext).pricing.netSales;
export const useCheckoutOutstanding = () => useContext(CheckoutContext).pricing.totalBalance;
export const useCheckoutServiceTypes = () => useContext(CheckoutContext).pricing.serviceTypes;
export const useCheckoutAdjustments = () => useContext(CheckoutContext).pricing.adjustments;
export const useCheckoutItems = () => useContext(CheckoutContext).pricing.items;

export const useSetCheckoutParams = () => useContext(CheckoutContext).setEditParams;
export const useIsCheckoutDirty = () => useContext(CheckoutContext).dirty;
export const useBookingStatus = () => useContext(CheckoutContext).bookingDoc;

export const useCheckoutBusinessPricing = () => useContext(CheckoutContext).pricingData;
export const useCheckoutPricingVersion = () => useContext(CheckoutContext).pricingData?.version;
export const useCheckoutBusinessServiceTypes = () => useContext(CheckoutContext).pricingData?.serviceTypes;
export const useCheckoutParams = () => useContext(CheckoutContext).checkoutParams || {};
export const useUpdateCheckoutParams = () => useContext(CheckoutContext).onUpdateCheckoutParams;

export const useLineItemsSteps = () => useContext(CheckoutContext).lineItemsSteps;
export const useCheckOutSupplierId = () => useContext(CheckoutContext).supplierId;

export const useCheckoutEditItem = () => useContext(CheckoutContext).editItem;
export const useSetCheckoutEditItem = () => useContext(CheckoutContext).setEditItem;

export const useCheckoutBusiness = () => useContext(CheckoutContext).businessCode;
export const useSetCheckoutBusiness = () => useContext(CheckoutContext).onSwitchBusiness;

export const useCheckoutService = () => useContext(CheckoutContext).service;
export const useSetCheckoutService = () => useContext(CheckoutContext).onSwitchService;

export const useCheckoutCustomer = () => useContext(CheckoutContext).customer;
export const useSetCheckoutCustomer = () => useContext(CheckoutContext).onSwitchCustomer;

export const useTogglePromotion = () => useContext(CheckoutContext).onTogglePromotion;
export const useClearCheckout = () => useContext(CheckoutContext).onClearCheckout;

export const useCheckoutEditOrder = () => useContext(CheckoutContext).editOrder;
export const useSetCheckoutEditOrder = () => useContext(CheckoutContext).setEditOrder;
