export default function (nameKey, context) {
  // Backward support;

  if (nameKey?.en) {
    return nameKey.en;
  }

  const {
    serviceTypes,
    businessCodes,
    accounts,
    serviceNames,
    stores,
    webapps,
    paymentMethods,
    workflows,
    procedureNames,
    variants,
    products,
    serviceOptions,
    priceLists
  } = context || {};

  return (
    (businessCodes && businessCodes[nameKey]) ||
    (stores && stores[nameKey]) ||
    (webapps && webapps[nameKey]) ||
    (accounts && accounts[nameKey]) ||
    (serviceNames && serviceNames[nameKey]) ||
    (paymentMethods && paymentMethods[nameKey]) ||
    (serviceTypes && serviceTypes[nameKey]) ||
    (procedureNames && procedureNames[nameKey]) ||
    (workflows && workflows[nameKey]) ||
    (variants && variants[nameKey]) ||
    (products && products[nameKey]) ||
    (serviceOptions && serviceOptions[nameKey]) ||
    (priceLists && priceLists[nameKey]) ||
    ""
  );
}
