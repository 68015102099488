"use client";

import { formatSignInAccount } from "@easybiz/utils";
import { getAuth, onIdTokenChanged, signOut } from "firebase/auth";
import { useEffect, useState } from "react";

export default (client) => {
  const [account, setAccount] = useState(undefined);

  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (account) => {
      try {
        if (account) {
          const token = await account.getIdTokenResult();
          const signInAccount = formatSignInAccount(account, token.claims, client);

          if (signInAccount) {
            setAccount(signInAccount);
          } else {
            signOut(getAuth());
            setAccount(false);
          }
        } else {
          setAccount(false);
        }
      } catch (err) {
        setAccount(false);
      }
    });
  }, [client]);

  return account;
};
