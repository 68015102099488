import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { SETTING_KEY_FACTORY_COMMON_NOTES } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { Button, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";

export default ({ disabled, selects, onChange }) => {
  const realmId = useRealmId();
  const [notes, setNotes] = useState();

  useEffect(() => {
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}/settings/${SETTING_KEY_FACTORY_COMMON_NOTES}`), (doc) =>
        setNotes(doc?.get("notes"))
      );
    }
  }, [realmId]);

  const dataSource = useMemo(() => {
    if (Array.isArray(notes) || Array.isArray(selects)) {
      const missingSelects = Array.isArray(selects)
        ? selects.filter((select) => !Array.isArray(notes) || !notes.find((note) => note === select))
        : [];
      return [...(notes || []), ...missingSelects];
    }
  }, [notes, selects]);

  if (Array.isArray(dataSource)) {
    return (
      <Space wrap>
        {dataSource.map((note) => {
          const selctIndex = Array.isArray(selects) ? selects.indexOf(note) : -1;

          return (
            <Button
              shape="round"
              disabled={disabled}
              type={selctIndex >= 0 ? "primary" : "default"}
              onClick={() => {
                if (selctIndex >= 0) {
                  onChange([...selects.slice(0, selctIndex), ...selects.slice(selctIndex + 1)]);
                } else {
                  onChange([...(selects || []), note]);
                }
              }}
            >
              {note}
            </Button>
          );
        })}
      </Space>
    );
  } else {
    return null;
  }
};
