"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { STATUSES_KEY_SALES_STATISTIC } from "@easybiz/utils";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (dateTag, businessCode) => {
  const realmId = useRealmId();
  const [sales, setSales] = useState(undefined);

  useEffect(() => {
    setSales(undefined);
    if (realmId && dateTag && businessCode) {
      return onSnapshot(
        doc(
          getFirestore(),
          `realms/${realmId}/statuses/${STATUSES_KEY_SALES_STATISTIC}/keys/${dateTag}`
        ),
        (doc) => setSales(doc.get(`data.${businessCode}`) || null)
      );
    }
  }, [realmId, dateTag, businessCode]);

  return sales;
};
