import { CalendarOutlined, DollarCircleOutlined, GiftOutlined, TabletOutlined, UserOutlined } from "@ant-design/icons";
import { useCheckoutCustomer, useCheckoutService } from "@easybiz/context/src/CheckoutContext";
import { useCustomerScreen } from "@easybiz/context/src/ConfigurationContext";
import { useCollectionOptions } from "@easybiz/context/src/LiveStateContext";
import {
  BUSINESS_SECTOR_LAUNDRY,
  CUSTOMER_SCREEN_DATE_TIME_OPTIONS,
  CUSTOMER_SCREEN_ORDER,
  CUSTOMER_SCREEN_PROFILE_FILL_IN,
  CUSTOMER_SCREEN_TOP_UP_OPTIONS,
} from "@easybiz/utils";
import { updateDoc } from "firebase/firestore";
import { Button, Col, List, Radio, Row, Typography } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

export default ({ onClose }) => {
  const customerScreen = useCustomerScreen();
  const customer = useCheckoutCustomer();
  const service = useCheckoutService();
  const collectionOptions = useCollectionOptions();

  return (
    <List
      style={{ width: 300 }}
      dataSource={[
        {
          mode: CUSTOMER_SCREEN_PROFILE_FILL_IN,
          icon: <UserOutlined style={{ fontSize: 18 }} />,
          title: customer ? (
            <FormattedMessage defaultMessage={"Customer account form"} />
          ) : (
            <FormattedMessage defaultMessage={"New customer account form"} />
          ),
          description: customer ? (
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  size="large"
                  block
                  onClick={() => {
                    updateDoc(customerScreen.ref, {
                      mode: CUSTOMER_SCREEN_PROFILE_FILL_IN,
                      customer: null,
                    });
                    onClose();
                  }}
                >
                  <FormattedMessage defaultMessage={"New account"} />
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="large"
                  block
                  onClick={() => {
                    updateDoc(customerScreen.ref, {
                      mode: CUSTOMER_SCREEN_PROFILE_FILL_IN,
                      customer,
                    });
                    onClose();
                  }}
                >
                  <Typography.Text ellipsis>
                    <FormattedMessage defaultMessage={"Edit {name}"} values={{ name: <b>{customer?.name}</b> }} />
                  </Typography.Text>
                </Button>
              </Col>
            </Row>
          ) : null,
        },
        {
          mode: CUSTOMER_SCREEN_ORDER,
          icon: <DollarCircleOutlined style={{ fontSize: 18 }} />,
          title: <FormattedMessage defaultMessage={"New order pricing overview"} />,
        },
        {
          mode: CUSTOMER_SCREEN_TOP_UP_OPTIONS,
          icon: <GiftOutlined style={{ fontSize: 18 }} />,
          title: <FormattedMessage defaultMessage={"Top up options"} />,
        },
        ...(service?.sector === BUSINESS_SECTOR_LAUNDRY
          ? [
              {
                mode: CUSTOMER_SCREEN_DATE_TIME_OPTIONS,
                icon: <CalendarOutlined style={{ fontSize: 18 }} />,
                title: <FormattedMessage defaultMessage={"Collection options"} />,
                params: { dateOptions: collectionOptions || [] },
              },
            ]
          : []),
        {
          mode: null,
          icon: <TabletOutlined style={{ fontSize: 18 }} />,
          title: <FormattedMessage defaultMessage={"Idle mode (Poster carousel)"} />,
        },
      ]}
      renderItem={({ mode, icon, title, description, params }) => {
        return (
          <List.Item
            className={description ? null : "selectable"}
            onClick={
              description
                ? null
                : () => {
                    updateDoc(customerScreen.ref, { ...params, mode });
                    onClose();
                  }
            }
            extra={description ? null : <Radio checked={mode === (customerScreen?.get("mode") || null)} />}
          >
            <List.Item.Meta avatar={icon} title={title} description={description} />
          </List.Item>
        );
      }}
    />
  );
};
