"use client";

import { useRealmId } from "@easybiz/context/src/ConfigurationContext";
import { formatAdjustment } from "@easybiz/utils";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default (serviceId, mode, formatData) => {
  const realmId = useRealmId();
  const [adjustments, setAdjustments] = useState(null);

  useEffect(() => {
    setAdjustments(null);
    if (realmId && serviceId && mode) {
      return onSnapshot(
        query(
          collection(getFirestore(), `realms/${realmId}/addons`),
          where("services", "array-contains-any", [serviceId, "ALL"]),
          where("mode", "==", mode)
        ),
        (result) =>
          setAdjustments(
            result.docs
              .sort((a, b) => a.get("value") - b.get("value"))
              .map((doc) => (formatData ? formatAdjustment(doc) : doc))
          )
      );
    }
  }, [realmId, serviceId, mode]);

  return adjustments;
};
