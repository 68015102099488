"use client";

import { useWorkflowUserId } from "@easybiz/context/src/WorkflowUserContext";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

function useApplicationLauncher() {
  const [showReload, setShowReload] = useState(false);
  const uid = useWorkflowUserId();

  useEffect(() => {
    if (uid) {
      const handler = setTimeout(() => {
        setShowReload(true);
      }, 10000);

      return () => clearTimeout(handler);
    }
  }, [uid]);

  return {
    title: uid ? (
      <FormattedMessage id="getting.everything.ready" defaultMessage={"Getting everything ready..."} />
    ) : (
      <FormattedMessage id="logging.in" defaultMessage={"Logging in..."} />
    ),
    showReload,
  };
}

export default useApplicationLauncher;
